import React from 'react';
function HomePage() {
    return (
      <div className="App">
        <header className="App-header">
  
          <h1>Cordial</h1>
        </header>
      
      </div>
    );
  }


export default HomePage;
  