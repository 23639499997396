import React from 'react';
function PortfolioPage() {
    return (
      <div className="App">

        <div className="structure   ">
			<div className="structure-inner ">
				<div className="container">
					<div className="row ">
						<div className="col ">
                            Portfolio - Dean Salleyman
						</div>
                              </div>
                              
                        <div className="row ">
							<div className="col ">
								<h1>Projects</h1>

								<div className="row justify-content-start">

									<div className="col">
										<div className="card" >
											<img className="card-img-top" src="http://becordial.s3-website-us-west-1.amazonaws.com/portfolio/3d/images/star-wars.png" alt="Card image cap" />
											<div className="card-body">
											  <h5 className="card-title">Star Wars Globe</h5>
											  <p className="card-text">Prototype built for Disney, Allows exploration of Star Wars related social trends in relation to a selected country. Tapping on a trend brings up a signal column  
										 on the selected country</p>
											  <a href="http://becordial.s3-website-us-west-1.amazonaws.com/portfolio/3d/3d_test/map" className="btn btn-primary">Star Wars Globe</a>
											</div>
										  </div>
									</div>

									<div className="col">
										<div className="card" >
											<img className="card-img-top" src="http://becordial.s3-website-us-west-1.amazonaws.com/portfolio/3d/images/Robot-world.png" alt="Card image cap" />
											<div className="card-body">
											  <h5 className="card-title">Robot World</h5>
											  <p className="card-text">Art installation - The interaction of the social media world and the data models being created from that. Participants  create their profile, using face recognition software. Entered from different remote locations. each entered profile creates a Bot that lives it's own life within the world   </p>
											  <a href="http://becordial.s3-website-us-west-1.amazonaws.com/portfolio/3d/web4" className="btn btn-primary">Robot World</a>
											</div>
										  </div>
									</div>
						
									<div className="col">
										<div className="card" >
											<img className="card-img-top" src="http://becordial.s3-website-us-west-1.amazonaws.com/portfolio/3d/images/Black-Swan-Airlines.png" alt="Card image cap" />
											<div className="card-body">
											  <h5 className="card-title">Black Swan Airlines</h5>
											  <p className="card-text">Prototype aircraft portal for display at Avionics conference. <small>Legacy so not optmized for Safari </small></p>
											  <a href="http://becordial.s3-website-us-west-1.amazonaws.com/portfolio/3d/black-swan-airlines/en_GB" className="btn btn-primary">Black Swan Airlines</a>
											</div>
										  </div>
									</div>
									<div className="w-100"></div>

							

	
									<div className="col">
										<div className="card" >
											<img className="card-img-top" src="http://becordial.s3-website-us-west-1.amazonaws.com/portfolio/3d/images/letterStream.png" alt="Card image cap" />
											<div className="card-body">
											  <h5 className="card-title">Letter Stream</h5>
											  <p className="card-text">WebGl part of art installation, builds up shapes from participants wishes to the art work

					
											  </p>
											  <a href="https://www.youtube.com/watch?v=As0m3XQjb8E" className="btn btn-primary">Letter Stream</a>
											</div>
										  </div>
									</div>

									<div className="col">
										<div className="card" >
											<img className="card-img-top" src="http://becordial.s3-website-us-west-1.amazonaws.com/portfolio/3d/images/FlightPath.png" alt="Card image cap" />
											<div className="card-body">
											  <h5 className="card-title">Flight Path</h5>
											  <p className="card-text">Interactive model for displaying current global flight path traffic.</p>
											  <a href="http://becordial.s3-website-us-west-1.amazonaws.com/portfolio/3d/one" className="btn btn-primary">Flight Path</a>
											</div>
										  </div>
									</div>

									

									<div className="col">
										<div className="card" >
											<img className="card-img-top" src="http://becordial.s3-website-us-west-1.amazonaws.com/portfolio/3d/images/Heatmap.png" alt="Card image cap" />
											<div className="card-body">
											  <h5 className="card-title">Heat-map</h5>
											  <p className="card-text">Heat-map on map feed of data science model projection of an areas projected house price</p>
											  <a href="https://www.youtube.com/watch?v=ktfdjowGq-Q" className="btn btn-primary">Heatmap</a>
											</div>
										  </div>
									</div>

									<div className="w-100"></div>


								</div>



								</div>
							</div>
                        </div>
                              
				</div>

			</div>
      
      </div>
    );
  }


export default PortfolioPage;
  